<template>
  <div class="row">
    <div class="col-12">
      <provider-import-data-external-datatable/>
    </div>
  </div>
</template>

<script>
import ProviderImportDataExternalDatatable from "@/pages/Registries/datatables/ProviderImportDataExternalDatatable";

export default {
  name: "ProviderImportDataExternalIndexPage",
  components: {ProviderImportDataExternalDatatable},
}
</script>

<style scoped>

</style>
