<template>
  <div>
    <octo-table
      show-filters
      :enable-store="false"
      :action="endpoints.DATATABLES.providerImportDataExternal"
      :fields="fields"
      :filters="filters"
      :order-by-items="['date']"
      name="ProviderImportDataExternalDataTable"
      @onNoResults="$emit('onSuccess')"
      @onResetFilters="resetFilters"
    >
      <template v-slot:name="data">
        <div class="text-uppercase text-truncate">{{ data.row.surname }} {{ data.row.name }}</div>
      </template>

      <template v-slot:provider="data">
        <div v-if="data.row.provider">
          <div class="text-uppercase small">{{ data.row.provider.name }}</div>
        </div>
      </template>

      <template v-slot:course="data">
        <div v-if="data.row.course_id">
          <div class="text-uppercase text-truncate small">{{ courses[data.row.course_id] | optional('title') }}</div>
        </div>
      </template>

      <template v-slot:date="data">
        <div class="text-uppercase text-truncate small">{{ data.row.date | date }}</div>
      </template>

      <template v-slot:actions="data">
        <div class="d-flex justify-content-end">
          <el-tooltip :content="$t('common.info')" :open-delay="300" placement="top">
            <base-button class="mx-1" size="sm" icon link @click="showModalData(data.row)">
              <octo-icon icon="magnifying-glass"/>
            </base-button>
          </el-tooltip>
        </div>
      </template>
      <template slot="custom-filters">
        <div class="col-12">
          <base-input :label="$t('datatable.courses')">
            <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              :placeholder="$t('common.type_to_search')"
              v-model="filters.courseIds"
            >
              <el-option
                v-for="course in courses"
                class="select-default text-uppercase"
                :value="course.id"
                :label="course.code + ': ' + course.title"
                :key="course.id"
              />
            </el-select>
          </base-input>
        </div>
        <div class="col-12 col-md-6">
          <base-input :label="$t('page.providers')">
            <el-select
              multiple
              filterable
              class="select-default text-uppercase"
              v-model="filters.providerIds"
            >
              <el-option
                v-for="option in providers"
                class="select-default text-uppercase w-100"
                :value="option.id"
                :label="option.name"
                :key="option.id"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-12 col-md-6">
          <base-input :label="$t('fields.date')">
            <el-date-picker
              v-model="filters.dateRange"
              type="daterange"
              align="right"
              unlink-panels
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              range-separator="-"
              :start-placeholder="$t('fields.date_start')"
              :end-placeholder="$t('fields.date_end')"
              :picker-options="pickerOptions"
            />
          </base-input>
        </div>
      </template>
    </octo-table>
    <modal centered :show.sync="showModal">
      <template slot="header">
        <h5 class="modal-title">{{ $t('common.details') }}</h5>
      </template>
      <ul class="list-group list-group-flush">
        <list-group-item-component
          v-for="(value, key) in providerImportDataSelected.data"
          v-bind:key="key"
          :value="value"
          :label="key"
        />
      </ul>
    </modal>
  </div>

</template>


<script>
import {Select, Option, DatePicker} from 'element-ui';
import {mapGetters} from "vuex";
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Modal from "@/components/Modal";
import ProviderImportData from "@/models/providerImportData";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";

export default {
  name: "ProviderImportDataExternalDatatable",
  components: {
    ListGroupItemComponent,
    Modal,
    OctoIcon,
    OctoTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      showModal: false,
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'name', label: 'name', minWidth: 200, slot: true},
        {prop: 'email', label: 'email', width: 150},
        {prop: 'phone', label: 'phone', width: 150},
        {prop: 'course', label: 'course', slot: true, minWidth: 200},
        {prop: 'provider', label: 'provider', slot: true, width: 200, align: 'center'},
        {prop: 'date', label: 'date', slot: true, width: 150, align: 'center'},
        {prop: 'actions', label: 'actions', slot: true, fixed: 'right'},
      ],
      providerImportDataSelected: this.$_.cloneDeep(ProviderImportData),
    }
  },
  computed: {
    ...mapGetters({
      courses: 'common/allCourses',
      providers: 'common/providers'
    }),

    pickerOptions() {
      return {firstDayOfWeek: 1};
    }
  },
  methods: {
    resetFilters: function () {
      this.filters = {};
    },

    showModalData(data) {
      this.providerImportDataSelected = this.$_.cloneDeep(data);
      this.showModal = true;
    },
  }
}
</script>

<style scoped>

</style>
